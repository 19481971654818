import React, { useImperativeHandle, useRef } from 'react'

import { DefaultFeatureFlags } from '@sketch-hq/sketch-web-renderer'
import { ErrorMessage } from '@sketch/components'
import { IS_EMBEDDED } from '@sketch/constants'
import { WebRendererCanvas } from '../../../../../components/WebRendererCanvas'
import { useCanvasPresentationFile } from '../../../../../components/WebRendererCanvas/hooks'
import { useTheme } from 'styled-components'

import ArtboardAnnotationOverlay from '../ArtboardAnnotationOverlay'
import LoadingState from '../../../components/LoadingState'
import { PageCanvasError } from '../../../PageCanvasView/components/PageCanvasError'

import { useCanvasAnnotations } from '../../../../annotations/hooks'
import { useInspectorContext, useInspectorHoveredElement } from '../../hooks'

import * as S from './FrameGroupCanvas.styles'
import {
  ArtboardDetailInspectorContextMenu,
  useInspectorContextMenu,
} from '../ArtboardDetailInspectorContextMenu'
import { ArtboardDetailInspectorTestOverlay } from '../ArtboardDetailInspectorTestOverlay'
import { getWebRenderCursor } from '../../../../inspector'
import { useObjectProxyAccumulator } from '@sketch/utils'
import { OriginContent } from '../../../../canvas/components'
import { FrameGroupInspectorOverlay } from '../ArtboardDetailInspectorOverlay'

interface FrameGroupCanvasProps {
  pageUUID: string
  // TODO: Rename artboardUUID to frameGroupUUID
  // https://linear.app/sketch/issue/SWEB-510
  artboardUUID: string
  isViewingLatestVersion?: boolean
}

const ARTBOARD_DETAIL_CANVAS_FEATURE_FLAGS: Partial<
  typeof DefaultFeatureFlags
> = {
  canvasLayersEvents: true,
}

const IS_RUNNING_TESTS =
  process.env.JEST_WORKER_ID !== undefined || window.Cypress

const PREVENT_CONTEXT_MENU = (event: MouseEvent) => {
  event.preventDefault()
}

export const FrameGroupCanvas = React.forwardRef<
  HTMLDivElement,
  FrameGroupCanvasProps
>(function FrameGroupCanvas(
  { pageUUID, artboardUUID, isViewingLatestVersion },
  ref
) {
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => canvasContainerRef.current!)

  const theme = useTheme()
  const { isInspectorActive, clickHandler, mouseMoveHandler, mouseDownHandle } =
    useInspectorContext()

  const { share, page, versionShortId, hasError, isLoading, isRendering } =
    useCanvasPresentationFile({
      pageUUID,
    })

  const {
    isPlacingDraftAnnotation,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    onPointerDown,
    onPointerUp,
    activeAnnotation,
    isAnnotationMoving,
  } = useCanvasAnnotations()

  const {
    handleContextMenuEvent,
    contextMenuPosition,
    handleCloseContextMenu,
  } = useInspectorContextMenu(canvasContainerRef)
  const hoveredElement = useInspectorHoveredElement()

  const webRenderContainerProps = useObjectProxyAccumulator(
    !isInspectorActive && {
      onContextMenu: PREVENT_CONTEXT_MENU,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      onPointerDown,
      onPointerUp,
    },
    isInspectorActive && {
      onContextMenu: handleContextMenuEvent,
      onClick: clickHandler,
      onMouseMove: mouseMoveHandler,
      onMouseDown: mouseDownHandle,
    }
  )

  if (isLoading) {
    return <LoadingState />
  }

  if (isRendering) {
    return (
      <ErrorMessage
        title="Rendering Canvas…"
        description="The canvas is rendering. When it’s ready, it will appear here."
        icon={<S.DocumentProcessing />}
      />
    )
  }

  if (hasError || !page) {
    return (
      <PageCanvasError
        description={
          <>
            There was a problem fetching the data. You can find more information
            on our <a href="https://status.sketch.com/">Status Page</a>.
          </>
        }
        showReloadCanvasAction
      />
    )
  }

  return (
    <S.Wrapper ref={canvasContainerRef}>
      <WebRendererCanvas
        pageUUID={pageUUID}
        artboardUUID={artboardUUID}
        page={page}
        versionShortId={versionShortId}
        shareIdentifier={share.identifier}
        backgroundColor={theme.colors.background.secondary.B}
        featureFlags={ARTBOARD_DETAIL_CANVAS_FEATURE_FLAGS}
        webRenderCursor={getWebRenderCursor(
          isPlacingDraftAnnotation,
          hoveredElement,
          isAnnotationMoving && !isViewingLatestVersion
        )}
        webRenderContainerProps={webRenderContainerProps.current}
      >
        {!IS_EMBEDDED && (
          <OriginContent>
            <ArtboardAnnotationOverlay
              permanentArtboardId={artboardUUID}
              parentWrapper={canvasContainerRef}
              activeAnnotationIdentifier={activeAnnotation}
            />
          </OriginContent>
        )}

        {isInspectorActive && (
          <>
            {IS_RUNNING_TESTS && <ArtboardDetailInspectorTestOverlay />}
            <FrameGroupInspectorOverlay
              canvasContainerRef={canvasContainerRef}
            />
            <ArtboardDetailInspectorContextMenu
              contextMenuPosition={contextMenuPosition}
              onCloseMenu={handleCloseContextMenu}
            />
          </>
        )}
      </WebRendererCanvas>
    </S.Wrapper>
  )
})
