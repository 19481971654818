import React, { FC } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  Caption,
  ErrorMessage,
  handleFetchMore,
  LoadPageSeparator,
  useBreakpoint,
} from '@sketch/components'
import {
  useGetNotificationsQuery,
  useNotificationMarkAsReadMutation,
  GetUserNotificationsCountDocument,
  NotificationInfoFragment,
} from '@sketch/gql-types'

import { getNotificationsQueryOptions } from '@sketch/user'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-filled-64'

import { useNotificationSearchContext } from '../../context/NotificationSearchContext'
import { getSearchString } from './NotificationSearch/Searchbar/searchUtils'

import { NotificationListItemSkeleton } from './NotificationListItemSkeleton'
import { NotificationsErrorDisplay } from './NotificationsErrorBoundary'

import {
  EmptyStateWrapper,
  LoadingWrapper,
} from './NotificationsListByType.styles'
import NotificationListItem from './NotificationListItem'
import { Skeleton } from './NotificationListItem.styles'

import { useOnEvent } from '@sketch/utils'

type NotificationState = 'READ' | 'UNREAD'

type NotificationsListByTypeProps = {
  notificationType?: NotificationState
}

const ENTRIES_PATH = ['me', 'notifications', 'entries']
const PAGE_SIZE = 4

/**
 * COMPONENT
 */
const NotificationsListByType: FC<NotificationsListByTypeProps> = props => {
  const { notificationType } = props

  const isTabletOrBigger = useBreakpoint('sm')
  const { search, options, isOptionsEmpty } = useNotificationSearchContext()

  const { data, fetchMore, loading, error, refetch } = useGetNotificationsQuery(
    {
      ...getNotificationsQueryOptions,
      variables: {
        filter: {
          search: getSearchString({ search, options }),
          state: notificationType,
        },
      },
    }
  )

  /**
   * Refresh the list when the user clicks on the "mark all as read" button on the UI
   */
  useOnEvent('userMarkedAllNotificationsAsRead', () => {
    refetch()
  })

  /**
   * Refresh the list when the user receives a new notification event
   */
  useOnEvent('userReceivedNewNotification', newNotification => {
    notificationType === 'UNREAD' && refetch()
  })

  const notificationsEntries = data?.me?.notifications?.entries
  const hasNotifications = (notificationsEntries?.length || 0) > 0

  const meta = data?.me.notifications?.meta

  // Mutations
  const [markNotificationAsRead] = useNotificationMarkAsReadMutation({
    onError: 'show-toast',
    redirectErrors: true,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetUserNotificationsCountDocument }],
  })

  if (loading) {
    return (
      <LoadingWrapper data-testid="notification-loading">
        <NotificationListItemSkeleton pageSize={PAGE_SIZE} />
      </LoadingWrapper>
    )
  }

  if (error || !notificationsEntries) {
    return <NotificationsErrorDisplay />
  }

  if (!hasNotifications) {
    return (
      <EmptyStateWrapper>
        <Caption fontSize="E" textAlign="center" m={0} as="div">
          {search || !isOptionsEmpty ? (
            <ErrorMessage
              icon={<BellIcon />}
              iconSize="medium"
              title="No notifications matching the search criteria"
            />
          ) : (
            <ErrorMessage
              icon={<BellIcon />}
              iconSize="medium"
              title={
                notificationType === 'UNREAD'
                  ? 'No unread updates'
                  : 'No read updates'
              }
            />
          )}
        </Caption>
      </EmptyStateWrapper>
    )
  }

  const renderNotification = (notification: NotificationInfoFragment) => (
    <NotificationListItem
      key={notification.identifier}
      isRead={!!notification.isRead}
      notification={notification}
      isVisuallyRead={notification.isRead || false}
      isDesktop={isTabletOrBigger}
      markNotificationAsRead={() =>
        markNotificationAsRead({ variables: { id: notification.identifier } })
      }
    />
  )

  return (
    <div data-testid="notification-items">
      {notificationsEntries.map(renderNotification)}
      {meta?.after && (
        <>
          <Skeleton>
            <LoadPageSeparator
              key={meta.after}
              loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
                dataIdFromObject,
                after: meta?.after,
              })}
            />
          </Skeleton>
          <NotificationListItemSkeleton pageSize={4} />
        </>
      )}
    </div>
  )
}

export default NotificationsListByType
