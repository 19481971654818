import styled from 'styled-components'
import { FilterFormContent, Input } from '@sketch/components'

export const FilterWrapper = styled.div`
  flex: 1;

  & ${/* sc-selector */ FilterFormContent}, ${/* sc-selector */ Input} {
    /* This is a work-around given the Search is forcing 40px when the elements are mobile */
    height: 32px;
  }
`
