import styled from 'styled-components'

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

export const LoadingWrapper = styled.div`
  flex: 1 0 0;
  overflow: hidden;
`
